
.test-papers-wrapper{
    background-color: #F5F5F5;
    display: flex;
    flex-direction: row;
    /* height: max-content; */
    align-items: stretch;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    min-height: 80vh;
  
}

.test-papers-container{
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
   width: 70%;
    padding:16px;
    height: max-content;
    flex-shrink: 0 !important;
   
}


.test-papers-filter-1,.test-papers-filter-2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 40px;
    width: 100%;
    margin: 5px 0px;
}
.test-papers-filter-wrapper{
display: flex;
flex-direction: row;
align-items: center;
gap:10px;

}

.test-papers-count span{
font-size: 14px;
color: #4D4D4D;
font-weight: 700;
}
.test-papers-sort, .test-papers-count{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:5px;
}
.layout-btn{
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 20px;
    cursor: pointer;
}
.layout-btn:hover{
    background-color: #E9E9E9;
}
.layout-btn.active{
  background-color: #1890FF;
}
.more-btn{
    width: 30x;
    height: 30px;
    border-radius: 3px;
}
.more-btn svg{
 font-size: 30px;
 font-weight: 700;

}
.more-btn:hover{
    transform: scale(1.2);


}

.dropdown-status .ant-dropdown-menu-title-content{
    min-width: 133px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #1F1F1F !important;
    opacity: 1 !important;
}
.test-papers-pagination{
    height: 68px;
    width: 100%;

    padding-top: 10px;

}

.test-paper-card-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 16px;
    flex-wrap:wrap;
    width: 100%;
    height: max-content;
    /* flex: 1;
    overflow-y: auto; */
}
.test-paper-card-container{
    /* max-width: 276px; */
    max-height: 190px;
    height: 100%;
    width:25%;
    min-width: 240px;
    max-width: 270px;
    min-height: 190px;
    padding: 20px;
    border-radius: 8px;
    background-color: #FFFFFF;
    text-decoration: none;
    color:#1F1F1F ;
    cursor: pointer;
    transition: 0.15s ease-in;
  
}
.test-paper-card-container:hover{
    color:#1F1F1F ;
    transform: scale(1.02);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    transition: 0.15s ease-out;
}
.test-paper-card-header{
    display: flex;
    flex-direction: row;
align-items: center;
    margin-bottom: 10px;
    gap: 10px;


}
.test-paper-card-header>div{
    display: flex;
    flex-direction: column;
}
.inactive-status{
    height: 28px;
    width: 67px;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
   align-self: center;
   margin-top: -20px;

}
.test-paper-card-title{
    color: #1890FF;
    font-size: 16px;
    margin: 4px 0px;
    width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    font-weight: 600;
}
.test-paper-card-field{
    margin: 4px 0px;
    color: #4D4D4D;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;

}
.test-paper-card-exp,.test-paper-card-location{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    color: #4D4D4D;
 
}
.ellipsis{
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
}
.test-paper-card-spec,.test-paper-card-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #4D4D4D;
 
}
.test-paper-card-info{
    justify-content: space-between;
    align-items: center;
    gap:2px;
    
   
}
.test-paper-card-info-dot{
    width: 4px;
    height: 4px;
    background-color: #4D4D4D;
    border-radius: 100%;
}
.test-paper-card-status,.bars-wrapper{
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    gap:5px;
    width: 100%;
}
.bars-status-info{
    display: flex;
    flex-direction: row;
   justify-content: space-between;
   align-items: center;
   width: 100%;


}

.bars-status-name{
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 80%;
   align-items: center;
}
.bars-status-name>div:nth-child(1){
width: 10px;
height: 10px;
background-color: #13C2C2;
border-radius: 100%;
}
.bars-status-name{
    color: #13C2C2;
    font-size: 14px;
}
.test-paper-card-date{
    color: #7A7A7A;
    font-size: 12px;
    font-weight: 400;
}
.test-paper-card-date span{
 font-weight: 600;
}


.filter-container{
    max-width: 200px;
    width: 30%;
    min-width: 200px;
    background-color: #FFFFFF;
    height: 100%;
    border-right: 1px solid rgb(233, 233, 233);
    padding: 20px;
    overflow: hidden auto; /* Hide overflow if content exceeds the max height */
    max-height: max-content !important; 
    flex: 1;
    border-bottom: 1px solid rgb(233, 233, 233);
    min-height:80vh;
   
  
}
.filter-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.filter-heading{
    font-size: 14px;
    font-weight: 600;
    color: #1F1F1F;
   
}
.filter-clearall{
    color: #1890FF;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
   
}

.filter-clearall:active{
    font-weight: 700;
}
.filter-type-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.filter-type-options{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    gap:6px;
}
.filter-type-option{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap:6px;
    align-items:baseline;
    font-size: 14px;
    color: #1F1F1F;
    font-weight:500;
}
.filter-type-option>span{
    color: #1890FF;
    font-size: 14px;
    font-weight: 600;
    margin-left: 22px;
    cursor: pointer;
  
}



 /* Hide scrollbar by default (but keep it in place) */
.filter-container::-webkit-scrollbar,.test-paper-card-wrapper::-webkit-scrollbar {
    width: 6px; /* Scrollbar width remains 4px but invisible due to opacity */
    height: 4px;
  

  }
  

  
  
  /* Hide the scrollbar track */
  .filter-container::-webkit-scrollbar-track,.test-paper-card-wrapper::-webkit-scrollbar-track {
    background: transparent; /* Make the track completely invisible */
    margin-right: 10px;
  }
  
  /* Customize the scrollbar thumb */
  .filter-container::-webkit-scrollbar-thumb,.test-paper-card-wrapper::-webkit-scrollbar-thumb {
    background-color: transparent;
 /* Scrollbar thumb color */

     border: none;
    border-radius: 10px; /* Rounded corners for the thumb */
 
  }

  .filter-container:hover::-webkit-scrollbar-thumb,.test-paper-card-wrapper:hover::-webkit-scrollbar-thumb{
    background-color: #e1e1e1;
    opacity: 0.8; 

    transition: 2s ease;

  
  }

  

  /*status bars styles*/
.bars-wrapper{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 2px 0px;
}
  .bars-container {
    display: flex;
    width: 100%;
    max-width: 100%;
  }
  
  .status-bar {
    --bar-height: 8px;
    position: relative;

    height: 8px;
    border-radius: calc(8px / 2);
    overflow: hidden;
  }

  
  .status-bar::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
   
  }
  
  .no-job-data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 70vh;
    background: white;
    border:  1.5px dashed #D0D0D0;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  .no-filter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 80vh;
    background: white;
   
    border-radius: 4px;
    margin-bottom: 20px;
  }
 
  .no-job-data img{
    width: 160px;
    height: 160px;

  }
  .no-job-data h3{
    margin: 2px 0px;
    font-size: 18px;
    color: #1F1F1F;
    font-weight: 700;
  }

  .no-job-data p{
    margin: 2px 0px;
    font-size: 14px;
    color: #4D4D4D;
    font-weight: 500;
  }

  .no-candidate-wrapper{

    background-color: #F5F5F5;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 24px;

  }

  .no-candidate-data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     width: 100%;
    height:94%;
    background: white;
    border:  1.5px dashed #D0D0D0;
    border-radius: 4px;
   
  }
  

  .no-candidate-data>img{
    width: 160px;
    height: 160px;
    margin-bottom: 14px;

  }
  .no-candidate-data>h3{
    margin: 4px 0px;
    font-size: 18px;
    color: #1F1F1F;
    font-weight: 700;
  }

  .no-candidate-data>p{
    margin: 2px 0px;
    font-size: 14px;
    color: #4D4D4D;
    font-weight: 500;
    max-width: 700px;
    text-align: center;
  }

  .add-btns{
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: center;
    align-items: center;
    margin: 34px 0px;
  }
  .add-candidate{

    border-radius: var(--Radius-XS, 4px);
    background: var(--Buttons-Secondary-default, #1890FF);
    border: 1.4px solid #1890FF;
    display: flex;
    padding:  10px 16px;
    justify-content: center;
    align-items: center;
    gap: var(--XS-3XS, 8px);
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }
  .bulk-upload{
    border-radius: var(--Radius-XS, 4px);
    background: var(--Buttons-Secondary-default, #FFFFFF);
  border: 1.4px solid #1890FF;
    display: flex;
    padding:  10px 16px;
    justify-content: center;
    align-items: center;
    gap: var(--XS-3XS, 8px);
    color: #1890FF;
    font-size: 14px;
    font-weight: 600;

  }
  .download-template{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 500px;
    width: 100%;
    height: 100%;
    max-height: 120px;
    border-radius: 4px;
    background: #E6F7FF;
    padding: 24px;
    color: #4D4D4D;
    margin-top: 60px;
  }
  .excel-div{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: flex-start;
    gap: 10px;
    width: 70%;

  }
  .excel-div>div{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 90%;
    color: #4D4D4D;
  }
  .excel-div>div>span{
    color: #1F1F1F;
    font-size: 16px;
    font-weight: 700;
  }
   .excel-div>div>img{
    width: 19px;
    height: 19px;
   }

   .template-div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: fit-content;
    gap: 10px;
    color: #1890FF;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
   }
   .template-div>span{
    width: max-content;
   }

   .options-list{
    padding: 2px;
   }

   .option-item{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 2px;
    height:20px;
    color:#1F1F1F;
    font-size:14px;
    font-weight:500;
    padding:4px 16px;
    cursor:pointer

   }
   .option-item:hover{
    background-color:  rgba(0, 0, 0, 0.04);
 

   }
   .selected,  .selected:hover{
    background-color:  #e6f4ff;

   }
   .add-candidate-modal .ant-modal-body{
    height: 400px;
    overflow-y: auto;
   }
   .bulk-upload-modal .ant-modal-body{
    height: 500px;
    overflow-y: auto;
   }
   .footer-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
   }
   .footer-wrapper button{
    border-radius: var(--Radius-XS, 4px);
    display: flex;
    padding:  10px 16px;
    justify-content: center;
    align-items: center;
    gap: var(--XS-3XS, 8px);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
   }
   .footer-submit{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;

   }
   .modal-done{
    background-color: #1890FF;
    color: #FFFFFF;
    border: 1.4px solid #1890FF;
   }
   .modal-next{
    background-color: #91D5FF;
    color: #FFFFFF;
    border: 1.4px solid #91D5FF;
    cursor: not-allowed !important;
   }
   .diable{
    background-color: #91D5FF;
    color: #FFFFFF;
    border: 1.4px solid #91D5FF;
    cursor: not-allowed !important;
   }
    .enable{
        background-color: #1890FF;
        color: #FFFFFF;
        border: 1.4px solid #1890FF;  
        cursor: pointer !important;
    }
   .modal-cancel,.modal-back{
    background-color: #FFFFFF;
    color: #4D4D4D;
    border: 1.4px solid #4D4D4D;
   }
   .modal-sub{
    color: #4D4D4D;
    font-weight: 500;
    font-size: 14px;
   }
   .upload-text{
    font-size: 14px;
    font-weight: 600;
    color: #1F1F1F;

   }
   .upload-text span{
    color: #1890FF;
   }

   .upload-hint{
    font-weight: 500;
    font-size: 12px;
    color:#7A7A7A;
   }

   .upload-resume-drag .ant-upload-btn{
    background-color: #FFFFFF !important;
    height: 330px !important;
   }
   .bulk-upload-modal    .upload-resume-drag .ant-upload-btn{
    height: 214px !important;
   }


   .bulk-upload-modal label, .add-candidate-modal label{
    color:#7A7A7A !important;
    font-weight:600;
    font-size:12px;
   }
   .upload-resume-drag  .ant-upload-list-item-name{
    color: #1890FF;
    font-weight: 500;
    font-size: 14px;

   }
   .upload-resume-drag .ant-upload-icon svg{
    fill:#4D4D4D
   }
   .upload-resume-drag  .ant-upload-list-item-actions button{
    opacity: 1 !important;

   }
   .upload-resume-drag  .ant-upload-list-item-actions button svg{
    fill:#F5222D;

   }
   .upload-resume-drag .ant-upload-list-item{
    cursor: pointer;
   }

   .bullet-dot{
    width: 4px;
    height: 4px;
    background-color: #000;
    border-radius: 100px;

   }
   .test-paper-profile-icon{
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    
   }
   .test-paper-profile-icon>img{
    border-radius: 100px;
    object-fit: contain;
   }
   .test-paper-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 10px;
   }
   .application-status{
    width: 100%;
    height: 95px;
    border-radius: 8px;
    padding: 12px;
    background-color: #F5F5F5;
    display: flex;
    flex-direction: column;
  
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
   }
   .status-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    
   }
   .applied-job-info{
    width: 70%;
   }
   .rating-wrapper{
    width: 30%;
   }
   .rating-hug{
    width: 55px;
    height: 22px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 999px;
    border: 1px solid #FFE7BA;
    background-color: #FFF7E6;
    color: #4D4D4D;
    font-weight: 600;
    font-size: 12px;
  

   }
   .applied-job-info h3{
    color: #1890FF;
    font-weight: 600;
    font-size: 14px;
    margin: 2px 0px;
    display: -webkit-box;       /* Enable flexbox layout for truncation */
  -webkit-line-clamp: 2;      /* Number of lines to show */
  -webkit-box-orient: vertical; /* Specify vertical orientation */
  overflow: hidden;           /* Hide overflowing text */
  text-overflow: ellipsis;
    
    
   }
   .applied-job-info  span{
    color: #4D4D4D;
    font-weight: 500;
    font-size: 12px;
   }
   .application-stage{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
   }
   .stage-dot{
    width: 9px;
    height: 9px;
    background-color: #13C2C2;
    border-radius: 100px;
   }
    .application-stage span{
       font-size: 12px;
       font-weight: 600;
       color: #13C2C2;
       width: 160px;
       overflow: hidden;
       text-overflow: ellipsis;
       white-space: nowrap; 
    }
    .application-footer{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  
    }
    .appliation-date-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }
 

    .test-paper-icon{
      background: #E6F7FF;
      border-radius: 8px;
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .test-paper-title{
      color: #7A7A7A;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      
    }
    .test-paper-title span {
      
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; 
    }
    
    .test-paper-card-field{
      color:#1F1F1F;
      font-size: 16px;
      max-width: 100%;
      font-weight: 600;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      
    }
    .test-paper-stats{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
    }
    .bullet-dot{
      color: #4D4D4D;
    }