@import url(https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap);
body,html {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;

}


*{
  font-family: figtree;
}
#root{
  width: 100%;
  height: 100%;
}


.animated-bg {
  background-image: linear-gradient(to right,
          #f6f7f8 0%,
          #edeef1 10%,
          #f6f7f8 20%,
          #f6f7f8 100%);
  background-size: 200% 100%;
  animation: bgPos 1s linear infinite;
}

.animated-bg-text {
  border-radius: 50px;
  display: inline-block;
  margin: 0;

}

@keyframes bgPos {
  0% {
      background-position: 50% 0;
  }

  100% {
      background-position: -150% 0;
  }
}

.forward-page:hover .more,.forward-right,.backward-page:hover .more,.backward-left{
  display: none;
}
.forward-page:hover .forward-right, .forward-page.more,.backward-page:hover .backward-left, .backward-page.more{
  display: block;
}

/* Hide scrollbar by default (but keep it in place) */
*::-webkit-scrollbar {
  width: 6px; /* Scrollbar width */
  height: 4px; /* Scrollbar height */
}

/* Hide the scrollbar track */
*::-webkit-scrollbar-track {
  background: transparent; /* Make the track completely invisible */
  margin-right: 10px;
}

/* Customize the scrollbar thumb */
*::-webkit-scrollbar-thumb {
  background-color: transparent; /* Initially transparent */
  border-radius: 10px; /* Rounded corners */
}

/* Show scrollbar thumb on hover */
*::-webkit-scrollbar-thumb {
  background-color: #e1e1e1; /* Scrollbar thumb color on hover */
  border: 1px solid #e0dfdf;
  transition: background-color 0.3s ease; /* Transition for smooth color change */
}


