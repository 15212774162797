.custom-multi-select .ant-select-selector {
    height: 34px; /* Fixed height */
    overflow-x: auto; /* Horizontal scrolling */
    overflow-y: hidden;
    display: flex;
    align-items: center; /* Vertically center content */
  }
  
  .custom-multi-select .ant-select-selection-item {
    white-space: nowrap; /* Prevent items from wrapping */
  }
  