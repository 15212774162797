
.activity-container{
    background-color: #F5F5F5;
    padding: 1.5rem;
}
.activity-subcontainer{
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
  position: relative;
    background-color: #F5F5F5;
    padding: 1.5rem;
    min-height: 100vh;
    padding-bottom: 64px;
 

}
.activity-subcontainer-subcontainer{
    display: flex;
    flex-direction: column;
    padding: var(--M-M, 24px);
    border-radius: var(--Radius-3XS, 8px);
    background: var(--Backgrounds-white, #FFF);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
    min-width: 696px;
    width: 60%;
  
    
}
.activity-subcontainer-subcontainer>div:nth-child(1){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.activity-subcontainer-subcontainer>div:nth-child(2){
    max-height: 110vh;
    padding-bottom: 20px;
    margin-top: 20px;
    overflow-y: scroll;
 
  
}

.activity-subcontainer-subcontainer>div:nth-child(1)>div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

/* .activity-subcontainer>div:nth-child(2){
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
} */
.activity-subcontainer-subcontainer>div:nth-child(1)>div:nth-child(1)>div{
    color: var(--Texts-title_neutral, #1F1F1F);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.activity-subcontainer-subcontainer>div:nth-child(1)>div:nth-child(2)>div{
    color: var(--Texts-captions, #7A7A7A);

    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.activity-select{
    display: flex;
width: 156px;
height: var(--M-3M, 32px);

}
.activities-timeline{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
   

}
.activities-timeline>div:nth-child(1){
    color: var(--Texts-title_neutral, #1F1F1F);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1rem;
   
}
.ant-timeline-item-content *{
    color: black !important;
    font-weight: 500;
    font-size: 14px;

    opacity: 1 !important;
}

.ant-timeline-item-content p b{
    color: black !important;
    font-weight: 700;
    font-size: 14px;

    opacity: 1 !important;
}