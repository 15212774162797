.card-header> div:nth-child(1){
    color: #1F1F1F;
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.card-header> div:nth-child(2){
    color:  #7A7A7A;


    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.candidate-notes-card .ant-card-body{
    max-height: 30vh;
    overflow-y: scroll;
   
}
.candidate-notes-card {
    transition: box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .candidate-notes-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .card-title-right .ant-dropdown-trigger {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .candidate-notes-card:hover .card-title-right .ant-dropdown-trigger {
    opacity: 1;
  }
  