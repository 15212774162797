.notification-body{
    width: max-content;
    min-height: 200px;
    max-width: 325px;
    height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: column;
}

.notification-body-text{
    width: 100%;
    height: max-content;
    min-height: 51px;
}
.notification-count{
  color:white; 
  width:12px;
  height:12px;
  background:red;
  border-radius:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  font-size:10px;
  font-weight: 700;
  position:absolute;
  top: 0px;
  left: 10px;

}
.notification-actions,.notification__card__actions{
    width: 100%;
    min-height: 30px;
    padding: 2px;
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
    align-items: center;
}
.notification-cancel{
    width: 65px;
    border: 1px solid #F5222D;
    border-radius: 2px;
    height: 30px;
    color: #F5222D;
    background:transparent;
    font-size: 13px;
    transition: 0.3s ease-in-out;
    font-weight: 700;
    cursor: pointer;
}
.notification-cancel:hover{
    background-color: #F5222D;
    color: #ffffff;
    transform: scale(1.02);
    transition: 0.3s ease-in-out;
}
.notification-ok{
    /* width: 65px; */
    color: #ffffff;
    background-color: #52C41A;
    border-radius: 2px;
    height: 30px;
    font-size: 13px;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    border: none;
    cursor: pointer;

}
.notification-ok:hover{
   
    transform: scale(1.09);
    transition: 0.3s ease-in-out;
}

/* notification sidebar */

.banner {
    min-height: 100%;
    width: 100%;
  
    display: flex;
    align-items: center;
    padding: 6px;
    color: #fff;

  }
  

  
  .sidebar {
    height: 100vh;
    width: 500px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    /* padding: 0 60px; */
    transform: translatex(100%);
    transition: transform 0.4s ease-in-out;
  }
  .sidebar__header{
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding: 24px;
    position: relative;
    /* width: 100%; */
    border-bottom:1px solid  #E9E9E9;
    
  }
  .sidebar__header h3{
    color: #1F1F1F;
    font-size: 20px;
    font-weight: 700;
  }
.sidebar__close svg{
    color: #4D4D4D;
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: 700;
}

 
 .active-notification-siderbar.sidebar {
    transform: translatex(0);
  }

.sidebar__body{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 90%;
    overflow: hidden scroll;
   
}
  .sidebar__notification__card{
     width: 90%;
     max-height: max-content;
     height: max-content;
     display: flex;
     flex-direction: column;
     gap: 10px;
     padding: 24px;
     border: 1px solid #E9E9E9;
  
  }
  .notification__card__header{
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;
    align-items: center;


  }
  .notification__card__profile{
    width: 50px !important;
    height: 50px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-size: 14px;
    font-weight: 700;
    color: #021c35;
    align-self: flex-start;
    margin-top: 4px;
    background-color: #1890FF;
  }
  .notification__card__content{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 80%;
  }
  .notification__card__title{
    font-size: 14px;
    font-weight: 500;
    color: #1F1F1F;

  }

  .notification__card__title p{
    margin: 6px 0px;
  }
  .notification__card__date{
    color: #7A7A7A;
    font-size: 12px;
    font-weight: 500;
  }
  .notification__card__actions{
width: 100%;
align-items: center;
  }

  .load-more-notifications{


    width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
    z-index: 100;
    height: 120px;
    margin: 40px 0px;
  
}
.load-more-notifications div{
    font-size:14px;
    font-weight: 700;
    width: max-content;
    height: 100%;
    gap: 10px;
    color: #1890FF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.no-notification-data{
  color:black;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height:100%;
  background-color:#fff;
}
.no-notification-data img{
  width: 160px;
  height: 160px;

}
.no-notification-data h3{
  margin: 2px 0px;
  font-size: 18px;
  color: #1F1F1F;
  font-weight: 700;
}

.notification-request-user{
  color: #1F1F1F;
}
.notification-active-link{
  color: #1890FF;
  text-decoration: none;


}
.notification-pending-link{
  color: #1F1F1F;
  text-decoration: none;
  font-weight: 700;

}
.notification-reason-heading{
  color: #1F1F1F;
  text-decoration: none;
  font-weight: 600;
  font-style: italic;
  
}
.notification-reason-body{
  color: #1F1F1F;
  text-decoration: none;
  font-weight: 400;
  font-style: italic;
}
.reason-actions{
  width: 100%;

}
.reason-action-btns{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 10px;
}
.reason-action-btns .notification-ok{
  background-color: #1890FF;
  color: #ffffff;
}
.reason-label{
  color: #1F1F1F;
  font-size: 14px;
  font-weight: 600;

 
}

.reason-label span{
color: #7A7A7A;
}

.reason-action-btns .notification-cancel{
  background-color: #fff;
  border: 1.3px solid #dfdede;
  color: #000;
}
.reason-action-btns .notification-cancel:hover{
  transform: scale(1.1);
}
._notification_clickable_element{
  cursor: pointer;
}






.notification-reason-container {
  position: relative;
  display: inline-block;
  max-width: 100%; /* Adjust to fit your layout */
  max-height: 200px;
  overflow-y: scroll;
}

.notification-reason-body {
  display: inline-block;
  max-width: 100%; /* Adjust the width as needed */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: top;
  line-height: 1.5;
 position: relative;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

/* When checkbox is checked, expand the content */
.read-more-toggle:checked ~ .notification-reason-body {
  white-space: normal; /* Allow text to wrap */
  overflow: visible;
  text-overflow: unset;
  display: block;
}

/* Style the read-more/read-less label */
.read-more-label {
  display: inline-block;
  color: #007bff; /* Link color */
  font-size: 14px;
  cursor: pointer;
  margin-left: 8px;
}

.read-more-toggle:not(:checked) ~ .read-more-label::after {
  content: 'Read More';
}

.read-more-toggle:checked ~ .read-more-label::after {
  content: 'Read Less';
}

/* Hide the checkbox */
.read-more-toggle {
  display: none;

}
.notification-accepted{
  cursor:default !important;
  color: #ffffff;
  background-color: #52C41A;
}

.notification-canceled{
  cursor:default !important;
  background-color: #F5222D;
  color: #ffffff;
}



