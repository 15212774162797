


/* 
height: 61px;
padding: 0px var(--L-3L, 48px) 0px 24px;

align-items: flex-end;
justify-content: center;

display: flex;
flex-direction: column;
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08); */
    /* display: flex;
   
    height: 6vh;
    padding: 0px var(--L-3L, 48px) 0px 24px;
    position: fixed;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    flex-shrink: 0;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
    background-color: #fff; */

/* Header.css */
.header-container {
    display: flex;
    height: 40px; /* Adjust if needed */
    padding: 0px var(--L-3L, 48px) 0px 24px;
    /* position: fixed; */
    /* top: 0;
    left: 0;
    right: 0; */
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    z-index: 100;
    position:absolute;
    width: 95%;
  
  }
  
  .header-container img {
    height: 24px; /* Set the icon size */
    width: auto;
  }
  
