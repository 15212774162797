.share-jd,.more-jd{
    width: 32px;
    height: 32px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   
    border-radius: 4px;

}
.share-jd svg,.more-jd svg{
    font-size: 18px;
    font-weight: 700;

}
.share-jd:hover,.more-jd:hover{
    background-color:#E9E9E9 ;
}


.share-jd:hover svg, .more-jd:hover svg{
    color: black;
    /* transform: scale(1.2); */
}
.delete-jd ,.delete-jd svg{
    color: #F5222D;
    font-size: 14px;
    font-weight: 600;
}

.export-jd, .export-jd svg{
    color: #1F1F1F;
    font-size: 14px;
    font-weight: 600;
}


.application-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: max-content;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
     gap: 20px;
    padding: 24px;
    padding-bottom: 64px;
}
.appliation-candidate-info{
    width: 450px;
    min-height:1033px ;
    max-height: max-content;
    background-color: white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding: 24px;
}
.application-tabs{
     width:100%;
    max-height:max-content;
    min-height: 700px;
    background-color: white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 20px 0px;

}
.application-info{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 1000px;

}
.candidate-additional-info{
    height:max-content;
    width: 100%;
    background-color: white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 20px;
}
.candidate-reference-info{
    max-height: 400px;
    overflow-y: scroll;
    min-height: 400px;
    height: max-content;
    width: 100%;
    background-color: white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 20px;
}


.profile-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.profile-icon, .profile-icon img{
width: 104px;
height: 104px;
border-radius: 100px;
display: flex;
justify-content: center;
align-items: center;
font-size: 32px;
font-weight: 600;
object-fit: contain;

}
.profile-section h3{
    padding: 0;
    margin: 2px 0px;
    font-size: 18px;
    font-weight: 600;

}
.name-wrapper,.hiring-stages{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hiring-stages{
    gap: 15px;
    margin: 15px 0px;
}
.application-section{
    background-color: #F5F5F5;
    border-radius: 8px;
    padding: 24px;
    width: 100%;
    height: max-content;
    min-height: 294px;
    margin-top: 20px;

}
.stage-box-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow-y: auto;
    gap: 10px;

}
.stage-box-wrapper::-webkit-scrollbar{
    display: none;
}
.hiring-stage-box{
    min-width: 30px;
    min-height: 32px;
    border-radius: 2px;
    border: 1.5px solid #D0D0D0;
    color: #D0D0D0;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.hiring-stage-box.active{
    border: 2px solid #13C2C2;
    font-weight: 600;
    color: #13C2C2;

}
.hiring-stage-box.completed{
    border: 2px solid #13C2C2;
    font-weight: 600;
    color: #ffffff;
    background-color: #13C2C2;
}
.personal-info-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    margin: 16px 0px;
}
.visit-profile{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    color: #1890FF;
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0px;
   
    
}
.visit-profile span{
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    gap: 5px;

}
.jobcard-info-red{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: #F5222D;
    font-weight: 600;
    font-weight: 12px;
    width: 100%;
    margin-bottom: 20px;
}