.job-industry-selector {
    display: flex;
    align-items: center;
    border: 1px solid #D0D0D0;
    /* padding: 10px; */
    border-radius: 5px;
    /* max-width: 600px; */
    width: 70%;
    max-width: 576px;
    padding: var(--S-3S, 16px) var(--M-M, 24px);
    height: 1rem;
    gap: var(--S-3S, 16px);
  }
  
  .job-title-input {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 14px;
    background: none;
    
  }
  
  .divider {
    height: 20px;
    width: 6px;
    background-color: #D0D0D0;
    margin: 0 10px;
  }
  
  .industry-select {
    border: none !important;

    background: none;
    outline: none;
    cursor: pointer;
    color: black !important;
    width: 100%;
    min-width: 160px;
  }
  .industry-select .ant-select-selector{
    border: none !important;
    box-shadow: none !important;
    min-width: 160px;
    font-size: 14px ;
  }

  






