
.vacancies-wrapper{
display: flex;
flex-direction: column;
height: 100%;
background-color:white;
position:absolute;
top:40px;
width: 100%;
overflow:auto;
}

.vacancies-container{
    display: flex;
    flex-direction: row;
    /* flex-direction: row; */
  /* width: 70vh; */
    /* height: 100vh; */
   
}
.vacancies-content{
    /* width: 70vh; */
    flex: 1;
    overflow: auto;
}
.vacancies-subcontent{
    /* display: flex; */
    flex-flow: row wrap;
    /* margin-top: 6vh; */
    /* margin-left: 30vh; */
    
}
.vacancies-subcontent>div:nth-child(1) {
    padding: var(--M-M, 24px) var(--L-3L, 48px) 0px var(--M-M, 24px);
    margin-top: 6vh;
   
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    

}
.vacancies-subcontent>div:nth-child(1) >div:nth-child(1){
    gap: 6px;
    display: flex;
    flex-direction: column;
}
.vacancies-subcontent >div:nth-child(1) >div:nth-child(1)>div:nth-child(1){
    color: var(--Texts-title_neutral, #1F1F1F);

        
    font-family: Figtree;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.vacancies-subcontent >div:nth-child(1) >div:nth-child(1)>div:nth-child(2){
    color: var(--Texts-body, #4D4D4D);

   
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1.5rem;
}
#exportReport{
    display: flex;
    flex-direction: row;
    gap: 8px;
    background-color: #fff;
    border-radius: var(--Radius-XS, 4px);
    border: 1px solid var(--Buttons-Secondary-default, #1890FF);
   padding: 10px 16px;
}
#exportReport>div{
color: #1890FF;
}

.tabCount{
    width: 28px;
    height: 21px;
    padding: 6px 4px;
    border-radius: 999px;

    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
