
.container{
  background-color: #f9f9f9;
min-height: 100vh;
width: 100%;
display: flex;
flex-direction: row;
justify-content:flex-start;
margin-bottom: 40px;
padding: 24px;

}
.card {
    background-color: white;
    border-radius: 5px;
    padding: 8px;
    box-shadow: 0px 2px 8px #00000013;
    
}

.title{
    color: var(--Texts-title_neutral, #1F1F1F);
/* Body/Body 03/Body 02/16 - Semi Bold */
font-family: Figtree;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.modalTitle{
    color: var(--Texts-title_neutral, #1F1F1F);

/* Body/Body 03/Body 02/16 - Semi Bold */
font-family: Figtree;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.dashedBox{
 
    border-radius: var(--XS-3XS, 8px);
border: 1px dashed var(--boarders-lines-neutral-subtle, #D0D0D0);
}
.box{
    border-radius: var(--XS-XS, 4px);
border: 1px solid var(--boarders-lines-neutral-light, #E9E9E9);
/* background: var(--Backgrounds-white, #FFF); */
}
.addBtn{
    background-color:#1890FF;
    color:white;
    width:18%;
    border:none;
    height:30px;
    border-radius: 4px;
}
.dashedCircle{
    border-radius: var(--Radius-Full, 999px);
border: 1px dashed var(--blue-6, #1890FF);
background: var(--blue-1, #E6F7FF);
}
.recruitModalCardAlign{
    min-height: 150px;
     margin-top: 24;
     display:flex;
     flex-direction:column;
     justify-content:space-between
}
.templateBox{
    padding: 16px;
    border-radius: var(--Radius-XS, 4px);
    border: 1px solid var(--boarders-lines-neutral-light, #E9E9E9);
    background: var(--Backgrounds-white, #FFF); 
    transition: border-color 0.3s ease;
}
.templateBox:hover {
    border-color: #1890FF; /* Set border color to blue on focus */
  }
  .characters {
    list-style: none;
    padding-left: 0;
  }
  
  .characters li {
    display: flex;
    align-items: center;
    border: solid 2px #d0d0d0;
    border-radius: .2em;
    padding: .5em .8em .5em .5em;
    margin-bottom: 1em;
  }
  
  .characters p {
    max-width: none;
    font-weight: bold;
    margin: 0;
  }
  
  .charactersThumb {
    overflow: hidden;
    flex-shrink: 0;
    width: 2em;
    height: 2em;
    background-color: #e8e8e8;
    padding: .5em;
    margin-right: .5em;
  }
  
  .charactersThumb img {
    display: block;
    width: 100%;
    height: auto;
  }
  .dragItems{
    padding: 16px;
    margin-bottom: 8px;
    background-color: #F0F0F0;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: grab;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  .flexSpaceBetween{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .inputStyle {
    width: 98%; 
    height: 30px;
    border:1px solid #1890FF;
    border-radius: var(--Radius-XS, 4px) 0px 0px var(--Radius-XS, 4px);
  
  }
  .inputStyle:focus {
    background: var(--Backgrounds-white, #fff); /* Ensures background remains white */
    border-color: var(--boarders-lines-neutral-subtle, #5c97dc); /* Optional: Keep the same border color */
    outline:#1890FF; /* Prevents any focus outline */
  }

/* .title {
    font-size: 24px;
    color: #333;
} */