.overviewCard-container{
    background-color: #fff;
    display: flex;
    width: 216px;
    padding: var(--M-M, 24px);
    flex-direction: column;
    min-height: 360px;
    /* gap: var(--M-3M, 32px); */
    border-radius: 8px;
}
.overviewCard-container > div:nth-child(1){
    color: #7A7A7A;
    margin-bottom: 2rem;
    font-family: Figtree;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}
.overviewCard-container > div:nth-child(2){
    align-items: center;
    display: flex;
    gap: 0.5rem;
    color: var(--Texts-captions, #7A7A7A);

/* Body/Body 03/14 - Regular */
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.spanClass{
   
    color: #1F1F1F;
    font-family: Figtree;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.overviewCard-contents{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
   /* margin-bottom: 0.5rem; */
}
.overviewCard-subcontents1{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    color: var(--Texts-title_neutral, #1F1F1F);

    /* Body/Body 03/14 - Regular */
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
   
}

.overviewCard-subcontents2{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    color: var(--Texts-title_neutral, #1F1F1F);
    text-align: right;

    /* Body/Body 03/14 - Semi Bold */
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}