.hiringstages-container{
    padding: 24px;
    background-color: #fff;
    min-width: 336px;
    max-height: fit-content;
    border-radius: 8px;
    height: 100vh;
}
.hiringstages-heading{
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.hiringstages-heading>div:nth-child(1){
    color: var(--Texts-title_neutral, #1F1F1F);

/* Body/Body 01/18 - Semi Bold */
font-family: Figtree;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.hiringstages-heading>div:nth-child(2){
    color: var(--Texts-captions, #7A7A7A);

/* Body/Body 01/18 - Regular */
font-family: Figtree;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.hiringstages-subheading{
    color: var(--Texts-captions, #7A7A7A);

    /* Body/Body 03/14 - Semi Bold */
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.ant-collapse-header{
    padding-left: 0px !important;
}
.ant-collapse-content-box{
    padding: 0px !important;
}