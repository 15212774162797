
.jobs-wrapper{
    background-color: #F5F5F5;
    display: flex;
    flex-direction: row;
    /* height: max-content; */
    align-items: stretch;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    min-height: 80vh;
  
}

.jobs-container{
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
   width: 70%;
    padding:16px;
    height: max-content;
    flex-shrink: 0 !important;
   
}


.jobs-filter-1,.jobs-filter-2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 40px;
    width: 100%;
    margin: 5px 0px;
}
.jobs-filter-wrapper{
display: flex;
flex-direction: row;
align-items: center;
gap:10px;

}

.jobs-count span{
font-size: 14px;
color: #4D4D4D;
font-weight: 700;
}
.jobs-sort, .jobs-count{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:5px;
}
.layout-btn{
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 20px;
    cursor: pointer;
}
.layout-btn:hover{
    background-color: #E9E9E9;
}
.layout-btn.active{
  background-color: #1890FF;
}
.more-btn{
    width: 30x;
    height: 30px;
    border-radius: 3px;
}
.more-btn svg{
 font-size: 20px;
 font-weight: 700;

}
.more-btn:hover{
    transform: scale(1.2);


}

.dropdown-status .ant-dropdown-menu-title-content{
    min-width: 133px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #1F1F1F !important;
    opacity: 1 !important;
}
.jobs-pagination{
    height: 68px;
    width: 100%;

    padding-top: 10px;

}

.jobcard-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 16px;
    flex-wrap:wrap;
    width: 100%;
    height: max-content;
    /* flex: 1;
    overflow-y: auto; */
}
.jobcard-container{
    /* max-width: 276px; */
    max-height: 260px;
    height: 100%;
    width:25%;
    min-width: 200px;
    max-width: 280px;
    min-height: 260px;
    padding: 20px;
    border-radius: 8px;
    background-color: #FFFFFF;
    text-decoration: none;
    color:#1F1F1F ;
    cursor: pointer;
    transition: 0.15s ease-in;
  
}
.jobcard-container:hover{
    color:#1F1F1F ;
    transform: scale(1.02);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    transition: 0.15s ease-out;
}
.jobcard-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;


}
.jobcard-header>div{
    display: flex;
    flex-direction: column;
}
.inactive-status{
    height: 28px;
    width: 67px;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
   align-self: center;
   margin-top: -20px;

}
.jobcard-title{
    color: #1F1F1F;
    font-size: 18px;
    margin: 4px 0px;
    width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
}
.jobcard-field{
    margin: 4px 0px;
    color: #4D4D4D;

}
.jobcard-exp,.jobcard-location{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    color: #4D4D4D;
 
}
.ellipsis{
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
}
.jobcard-spec,.jobcard-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #4D4D4D;
 
}
.jobcard-info{
    justify-content: space-between;
    align-items: center;
    gap:2px;
   
}
.jobcard-info-dot{
    width: 4px;
    height: 4px;
    background-color: #4D4D4D;
    border-radius: 100%;
}
.jobcard-status,.bars-wrapper{
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    gap:5px;
    width: 100%;
}
.bars-status-info{
    display: flex;
    flex-direction: row;
   justify-content: space-between;
   align-items: center;
   width: 100%;


}

.bars-status-name{
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 80%;
   align-items: center;
}
.bars-status-name>div:nth-child(1){
width: 10px;
height: 10px;
background-color: #13C2C2;
border-radius: 100%;
}
.bars-status-name{
    color: #13C2C2;
    font-size: 14px;
}
.jobcard-date{
    color: #7A7A7A;
    font-size: 12px;
    font-weight: 400;
}
.jobcard-date span{
 font-weight: 600;
}


.filter-container{
    max-width: 200px;
    width: 30%;
    min-width: 200px;
    background-color: #FFFFFF;
    height: 100%;
    border-right: 1px solid rgb(233, 233, 233);
    padding: 20px;
    overflow: hidden auto; /* Hide overflow if content exceeds the max height */
    max-height: max-content !important; 
    flex: 1;
    border-bottom: 1px solid rgb(233, 233, 233);
    min-height:80vh;
   
  
}
.filter-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.filter-heading{
    font-size: 14px;
    font-weight: 600;
    color: #1F1F1F;
   
}
.filter-clearall{
    color: #1890FF;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
   
}

.filter-clearall:active{
    font-weight: 700;
}
.filter-type-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.filter-type-options{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    gap:6px;
}
.filter-type-option{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap:6px;
    align-items:baseline;
    font-size: 14px;
    color: #1F1F1F;
    font-weight:500;
}
.filter-type-option>span{
    color: #1890FF;
    font-size: 14px;
    font-weight: 600;
    margin-left: 22px;
    cursor: pointer;
  
}



 /* Hide scrollbar by default (but keep it in place) */
.filter-container::-webkit-scrollbar,.jobcard-wrapper::-webkit-scrollbar {
    width: 6px; /* Scrollbar width remains 4px but invisible due to opacity */
    height: 4px;
  

  }
  

  
  
  /* Hide the scrollbar track */
  .filter-container::-webkit-scrollbar-track,.jobcard-wrapper::-webkit-scrollbar-track {
    background: transparent; /* Make the track completely invisible */
    margin-right: 10px;
  }
  
  /* Customize the scrollbar thumb */
  .filter-container::-webkit-scrollbar-thumb,.jobcard-wrapper::-webkit-scrollbar-thumb {
    background-color: transparent;
 /* Scrollbar thumb color */

     border: none;
    border-radius: 10px; /* Rounded corners for the thumb */
 
  }

  .filter-container:hover::-webkit-scrollbar-thumb,.jobcard-wrapper:hover::-webkit-scrollbar-thumb{
    background-color: #e1e1e1;
    opacity: 0.8; 

    transition: 2s ease;

  
  }

  

  /*status bars styles*/
.bars-wrapper{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 2px 0px;
}
  .bars-container {
    display: flex;
    width: 100%;
    max-width: 100%;
  }
  
  .status-bar {
    --bar-height: 8px;
    position: relative;

    height: 8px;
    border-radius: calc(8px / 2);
    overflow: hidden;
  }

  
  .status-bar::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
   
  }
  
  .no-job-data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 70vh;
    background: white;
    border:  1.5px dashed #D0D0D0;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  .no-filter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 80vh;
    background: white;
   
    border-radius: 4px;
    margin-bottom: 20px;
  }
 
  .no-job-data img{
    width: 160px;
    height: 160px;

  }
  .no-job-data h3{
    margin: 2px 0px;
    font-size: 18px;
    color: #1F1F1F;
    font-weight: 700;
  }

  .no-job-data p{
    margin: 2px 0px;
    font-size: 14px;
    color: #4D4D4D;
    font-weight: 500;
  }
  