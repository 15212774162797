.drawer-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.drawer-container>div:nth-child(1){
    display: flex;
    flex-direction: row;
    gap:24px;
}
.drawer-container>div:nth-child(1)>div:nth-child(2)>div:nth-child(1){
    color: var(--Texts-title_neutral, #1F1F1F);

/* Body/Body 01/18 - Semi Bold */
font-family: Figtree;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.drawer-container>div:nth-child(1)>div:nth-child(2)>div:nth-child(2){
    color: var(--Texts-body, #4D4D4D);

/* Body/Body 03/14 - Regular */
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
