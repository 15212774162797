.app{
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
}
.app-sidebar{
  
    height: 100vh;
  
}
.app-main{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}
.no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge, and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
 