/* Align text to the left */
.resume-demo-modal .ant-form-item-label {
    text-align: left !important;
  }
  
  /* Ensure proper spacing and alignment */
  .resume-demo-modal .ant-form-item-control {
    text-align: left;
  }
  .resume-demo-modal .ant-modal-content {
    width: 416px;
    max-height: 597px;
  }
  