.createJD-container{
    position: relative;
    height: 100vh;
}
.createJD-child{
    align-items:center;
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 10%;
    height: 80%;
    text-align: center;
    transform: translateY(200px);
    
}
.heading-content{
    color: var(--Texts-title_neutral, #1F1F1F);
text-align: center;

/* Title/32 - Bold */
font-family: Figtree;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 0.5rem;
}
.paragraph-content{
    color: var(--Texts-body, #4D4D4D);
text-align: center;
width: 100%;
padding: 10px 20px;
/* Body/Body 03/Body 02/16 - Regular */
font-family: Figtree;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.heading-content1{
    color: var(--Texts-title_neutral, #1F1F1F);
text-align: center;

/* Title/18 - Bold */
font-family: Figtree;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 4rem;
margin-bottom: 1.5rem;
}
.paragraph-content1{
    display: flex;
padding: 8px 24px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: var(--XS-XS, 4px);
}
.flex-overlay-footer{
    border-style: dashed;
    border-color: #FFEC3D;
    background-color: #FEFFE6;
    border-radius: 5px;
    max-width: fit-content;
    height: 2rem;
    gap: 4px;
    align-items: center;
    display: flex;
    justify-content: center;
    /* position: relative; */
      /* z-index: 3; */
    padding: 6px 17px;
    position: relative;
      
   margin: 30px 0px;
      
   font-size: 12px; 
  
  /*   
      margin: 20px;
      padding:0px 20px ; */
  }
  
  .flex-overlay-footer > span{
    font-weight: 700;
    color: #4D4D4D;
    font-size: 12px;
   
  }

  .flex-overlay-container {

    position: absolute;
    width: 100%;
    height: 50vh;
   min-height: 300px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(18, 216, 250, 0.25) 50%, rgba(166, 255, 203, 0.50) 100%);
   z-index: -1;
   top: 0;
     
  }
  
  
  
  .image2 {
    position: absolute;
    width: 100%;
    height: 100%;
   object-fit: cover;
   top: 0;
   left: 0;
   right: 0;
  
  }
  
  
  
  .job-description {
    /* margin: 20px auto;
    max-width: 800px; */
    display: flex;
    flex-direction: column;
  
    align-items: center;
    width: 100%;
    
  
  
  }


 