.lineHeight{
    height:20px;
    width:4px;
    background-color:#2337C6;
    margin-right:4px
    
    
    
}
.container{
    background-color: #f9f9f9;
 
  width: 100%;
  /* display: flex;
  flex-direction: row; */
  justify-content:flex-start;
  margin-bottom: 40px;
  padding: 24px;
  
  height: 100vh;
  overflow-y: auto
 
  
  }
  .card {
    background-color: white;
    border-radius: 5px;
    padding: 8px;
    box-shadow: 0px 2px 8px #00000013;
    min-height: fit-content;
    
}
.center{
    display:flex;
    justify-content:center;
    align-items:center 
}
.appHistoryBox{
    border-radius: var(--Radius-XS, 4px);
background: var(--Backgrounds-light, #F5F5F5);
}
.boxAlign{
    display: flex;
padding: var(--XS-3XS, 8px) var(--S-S, 12px);
align-items: center;
gap: 10px;
}
.greenBox{
    border-radius: var(--Radius-Full, 999px);
border: 1px solid var(--green-2, #D9F7BE);
background: var(--green-1, #F6FFED);
padding: 4px;
}
.applHistoryBox{
    display: flex;
padding-bottom: var(--M-M, 8px);
flex-direction: column;
align-items: flex-start;
gap: 20px;
align-self: stretch;
}
.deleteBin{
    align-items:center;
    justify-content:flex-end;
    width:48%;
    display:flex;
    height:12;
    margin-right:8;
    margin-top:8;
    color:red;
    font-weight:600;
    gap:6px
}
.candImageCircle{
    height: 100px;
    width: 100px;
    border-radius: 50px;
    background-color: #EFDBFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600px;
    font-size: 24px;
    position: relative;
}
.candName{
  display:flex;
  font-weight:500;
  font-size:20px;
  justify-content:center;
  align-items:flex-end;
  height:50px
}
.profileLabels{
    color:#7A7A7A;
    font-weight:600
}