 .cardStyle {
     background-color: white;
     border: 1px solid #ccc;
     width: "30%";
     border-radius: 8px;
     padding: 16px;
     min-height: 200px;
     text-align: center;
     font-size: 16px;
     box-shadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
 }

 .gridStyle {
     display: grid;
     
     grid-template-columns: repeat(3, 30%);
     gap: 40px;
     /* padding: 20px; */
     background-color: "red";
 }

 .sprite {
     display: inline-block;
     width: 50px;
     /* width of individual icon */
     height: 50px;
     /* height of individual icon */
     background-image: url('../../../../assets/DocumentIcon.svg');
     background-repeat: no-repeat;
     /* background-size: calc(100% * 3); ; */
 }

 .pdf {
     background-position: -464px -198px;
     width: 65px;
     height: 68px;
 }

 .jpg {
     background-position: -817px -110px;
     width: 61px;
     height: 68px;
 }
 .jpeg {
    background-position: -817px -110px;
    width: 61px;
    height: 68px;
}
 .txt {
     background-position: -465px -286px;
     width: 61px;
     height: 68px;
 }

 .png {
     background-position: -643px -199px;
     width: 60px;
     height: 67px;
 }

 .xlsx {
     background-position: -644px -285px;
     width: 58px;
     height: 69px;
 }
 .doc{
    background-position: -111px -111px; 
width: 64px;
height: 67px;
 }
 .uploadButton{
    padding: 12px;
    border-radius: var(--Radius-XS, 4px);
background: var(--Buttons-Secondary-default, #1890FF);
border-color:#1890FF;

 }
 .inputStyle {
    width: 98%; 
    height: 30px;
    border:1px solid #1890FF;
    border-radius: var(--Radius-XS, 4px) 4px 4px var(--Radius-XS, 4px);
  
  }
  .inputStyle:focus {
    background: var(--Backgrounds-white, #fff); /* Ensures background remains white */
    border-color: var(--boarders-lines-neutral-subtle, #5c97dc); /* Optional: Keep the same border color */
    outline:#1890FF; /* Prevents any focus outline */
  }
  .center{
    display:flex;
    justify-content:center;
    align-items:center 
}