.tabs-heading{
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    color: var(--Buttons-Secondary-default, #D0D0D0);

/* Body/Body 03/Body 02/16 - Semi Bold */
font-family: Figtree;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}