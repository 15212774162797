
.dashboard-wrapper{
    width: 100%;
height: 100%;
margin-top: 40px;
}
.dashboard-container{
width: 100%;
height: 100%;
padding: 24px;
}
.dashboard-heading{
    font-size: 20px;
    font-weight: 700;
    color: #1F1F1F;
}
.dashboard-subheading{
    font-size: 14px;
    font-weight: 700;
    color: #4D4D4D;
}