.input-resumeScreening > label{
    color: var(--Texts-captions, #7A7A7A);

    /* Sub-title/12 - Semi Bold */
    font-family: Figtree;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
}