.summarycard-container{
    display: flex;
min-width: 396px;
width: 40%;
padding: var(--M-M, 24px);
flex-direction: column;
/* align-items: flex-start; */
gap: var(--M-M, 24px);
/* height: 208px; */
border-radius: var(--Radius-3XS, 8px);
background: var(--Backgrounds-white, #FFF);
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
margin-bottom: 1rem;
}
/* .summarycard-container>div:nth-child(1){
   
    display: flex;
align-items: center;
gap: 16px;
align-self: stretch;

} */
 .summarycard-subcontainer1{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
 }
 .summarycard-subcontainer1>div:nth-child(2)>div:nth-child(1){
    color: var(--Texts-title_neutral, #1F1F1F);

/* Body/Body 03/Body 02/16 - Semi Bold */
font-family: Figtree;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
 }
 .summarycard-subcontainer1>div:nth-child(2)>div:nth-child(2){
    color: var(--Texts-body, #4D4D4D);

/* Sub-title/12 - Regular */
font-family: Figtree;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
 }
 .summarycard-subcontainer1>div:nth-child(1) img{
    display: flex;
width: 40px;
height: 40px;
padding: 0.364px;
justify-content: center;
align-items: center;
 }
 .summarycard-subcontainer2>div:nth-child(1){
    color: var(--Texts-title_neutral, #1F1F1F);
font-family: Figtree;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
 }
 .summarycard-subcontainer2>div:nth-child(2){
    color: var(--Texts-body, #4D4D4D);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
 }
.summarycard-subcontainer2>div:nth-child(3) {
    color: var(--Texts-disabled, #D0D0D0);
    font-family: Figtree;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
 }
 .img-summarycard{
    display: flex;
width: 16px;
height: 16px;
padding: 1px;
flex-direction: column;
justify-content: space-between;
align-items: center;
 }

 .summarycard-subcontainer3{
    color: var(--Texts-body, #4D4D4D);

/* Sub-title/12 - Regular */
font-family: Figtree;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
gap: 1rem;
display: flex;
flex-direction: column;

 }