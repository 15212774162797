.tooltip {
    position: relative;
   }
   
   .tooltip::before {
    position: absolute;
    top: 28px;
    left: 40%;
    transform: translatex(0%) rotate(135deg);
    content: "";
    display: none;
    width: 0;
    height: 0;
    border: 8px solid #282828;
    border-top-color: transparent;
    border-right-color: transparent;
    z-index: 10000;
   }
   
   .tooltip::after {
    display: none;
    position: absolute;
    content: attr(data-tooltip);
    top: calc(100% + 8px);
    left: -5px;
    right: 0;
    min-width: max-content;
    padding: 8px;
    background-color: #282828;
    border-radius: 8px;
    color: #ffffff;
    z-index: 10000;
   }
   
   .tooltip:hover:not(:focus)::before,
   .tooltip:hover:not(:focus)::after {
    display: block;
   }
   