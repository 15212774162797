.renderResults{

    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    padding-left: 0px;
    justify-content: space-between;

}
.renderResults > div:nth-child(1){
    
    color: var(--Buttons-Warning-default, #F5222D);
    text-align: center;
    
    /* Title/24 - Bold */
    font-family: Figtree;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 90px;
   max-width: fit-content;
    
}
.renderResultsCriteria > div:nth-child(1){
    color: var(--Texts-title_neutral, #1F1F1F);

/* Body/Body 01/18 - Semi Bold */
font-family: Figtree;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-align: left;
} 
.renderResultsCriteria > div:nth-child(2){
    color: var(--Texts-body, #4D4D4D);

/* Body/Body 03/14 - Regular */
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: left;
}
.results-header{
    color: var(--Texts-title_neutral, #1F1F1F);

/* Title/32 - Bold */
font-family: Figtree;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.results-desc{
    color: var(--Texts-body, #4D4D4D);

/* Body/Body 03/14 - Regular */
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.result-percentage{
    color: var(--Texts-title_neutral, #1F1F1F);
text-align: center;

/* Title/24 - Bold */
font-family: Figtree;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
}