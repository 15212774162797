.dot {
    height: 3px;
    width: 3px;
    background-color: #4D4D4D;
    border-radius: 50%;
    display: inline-block;
  }
  .timeline-date{
    color: var(--Texts-captions, #7A7A7A);
    font-family: Figtree;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .activity  *{
    list-style-type: disc !important;
  
  }