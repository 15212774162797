
.chart-container {
    
    /* min-width: 456px;
    height: 357px;
    padding: 32px; Example padding
    border-radius: 8px;
  background: var(--Neutral-0, #FFF);
  margin-right: 24px; */

  display: flex;
width: 396px;
height: 392px;
padding: var(--M-M, 24px);
flex-direction: column;
align-items: flex-start;
/* gap: var(--M-M, 24px); */
flex-shrink: 0;
background-color: #fff;
gap: 24px;
 
  }
  .chart-container>div {
display: flex;
flex-direction: column;

  }

.chart-container>div:nth-child(1)>div:nth-child(1){
 
  color: var(--Texts-title_neutral, #1F1F1F);
  font-family: Figtree;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.chart-container>div:nth-child(1)>div:nth-child(2){
  color: var(--Texts-body, #4D4D4D);
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* .chart-container>div:nth-child(2)>div>div:nth-child(1){
  color: var(--Texts-title_neutral, #1F1F1F);
font-family: Figtree;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
} */