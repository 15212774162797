.job-level-container {
    display: flex;
    align-items: center;  /* Align items horizontally */
    justify-content: space-between; /* Ensure spacing between the content and separator */
    font-family: 'Arial', sans-serif;
    color: #333;
    padding: 10px;
    /* border: 1px solid #ccc; */
    cursor: pointer;
    transition: background-color 0.3s;
    flex-direction: column;
  }
  
  .job-level-container.active {
    background-color: #E6F7FF; /* Light blue background when active */
  }
  
  .content {
    display: flex;
    flex-direction: column; /* Stacks the title and experience vertically */
    /* justify-content: center; */
    /* align-items: flex-start; */
    width: 8rem;
    padding: 8px 24px;
    height: 3rem;
    gap: var(--XS-XS, 4px);
  }

  .content.active{
  
    background-color: #E6F7FF; 
  }
  
  
  .job-level-title {
    color: var(--Texts-title_neutral, #1F1F1F);

/* Body/Body 03/Body 02/16 - Semi Bold */
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .job-level-experience {
    color: var(--Texts-body, #4D4D4D);

/* Body/Body 03/14 - Regular */
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  /* Separator on the right side */
  .separator {
    height: 100%;  /* Full height of the container */
    width: 1px;    /* Thin vertical line */
    background-color: #ccc;
    margin-left: 10px; /* Space between the content and separator */
  }
  
  .divider {
    height: 40px;
    width: 1px;
    background-color: #D0D0D0;
 
  }
  