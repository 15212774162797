.details-header{
    display: flex;
    flex-direction: row;
    gap: 4px;
}
.details-header>div:nth-child(2){
    font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
color: #4D4D4D;
}