/* Header Styles*/
.landing_header{
    display: flex;
    flex-direction: row;
    height: 100px;
    position: relative;
    z-index: 3;
    
}
.landing_logo{

width: 30%;
height: 100%;
position: relative;

}
.landing_logo a{
    width: 197px;
    width: 48px;
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
top: 48px;
left: 108px;
padding: 12px 16px 12px 16px;
gap: 10px;

position: absolute;
    

}

@media screen and (max-width:990px){
    .landing_logo a{
        width: 197px;
        width: 48px;
        text-decoration: none;
        font-size: 20px;
        font-weight: 600;
        color: #FFFFFF ;
    top: 18px;
    left: 28px;
    padding: 12px 16px 12px 16px;
    gap: 10px;
    
    position: absolute;
        
    
    }
}


/*Footer Styles*/
.landing_footer{
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
    padding:0px 20px ;

}


.landing_copy-right{
font-size: 16px;
font-weight: 400;
line-height: 19.2px;
text-align: center;

height: 19px;
gap: 0px;

color: #FFFFFF !important;
}
.landing_contact-info{
 
    height: 19px;
    gap: 0px;
    
    color: #FFFFFF !important;
font-size: 16px;
font-weight: 400;
line-height: 19.2px;
text-align: center;

}
.landing_contact-info b{

font-size: 16px;
font-style: italic;
font-weight: 600;
line-height: 19.2px;
text-align: center;
color: #FFFFFF !important;
}

@media screen and (max-width:990px){
    .landing_footer{
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 20px;
        gap: 36px;
        padding: 20px;

    
    }
    .landing_contact-info,.landing_contact-info b{
        color: #FFFFFF !important;
    }
}

/*Home page Styles*/

.landing_home{
    background:linear-gradient(to right,#0504AA, #031273, #010523);
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.landing_main{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 76%;
 z-index: 3;
 position: relative;
gap:10px
 
}

.landing_main section{
    width: 50%;
    height: 100%;

}
.landing_left-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
 
}

 
.landing_left-section > div{
    width: 100%;
    height: 100%;
    z-index: 3;
    position: relative;
}

.landing_right-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.landing_content{
    width: 516px;
height:187px;
position: relative;
gap: 24px;



}
.landing_content h1{
font-size: 96px;
font-weight: 700;
line-height: 115.2px;
text-align: left;
color: #FFFFFF !important;
width: 516px;
height: 115px;
gap: 0px;

margin: 0;
animation:fadeInUp 1s ease backwards;
animation-delay: 1s;
}

.landing_content span{
    width: 516px;
    height: 48px;
    gap: 0px;
    margin: 0;
    
font-size: 20px;
font-weight: 400;
line-height: 24px;
text-align: left;
color: #FFFFFF !important;
animation:fadeInUp 1s ease backwards;
animation-delay: 2s;
}

@keyframes fadeInUp{
  0%{transform:translate(0px, 100px); opacity: 0;}
  100%{transform:translate(0px, 0); opacity: 1;}
}







.landing_top-lines,.landing_bottom-lines{
    height: 180px;
    width: 100%;
    position: relative;

}





.landing_line1,.landing_line2,.landing_line3,.landing_line4,.landing_line5{
    position: absolute;
    width: 311.13px;
height: 9.52px;
gap: 0px;

background: linear-gradient(90deg, #90FFFF -0.01%, rgba(136, 242, 244, 0.95) 8.99%, rgba(118, 209, 217, 0.81) 26.99%, rgba(88, 155, 172, 0.58) 51%, rgba(46, 83, 112, 0.27) 81%, rgba(11, 19, 59, 0) 100%);
background-blend-mode: color-dodge;
border-radius: 6px;

}
.landing_line1{
    top: 3px;
left: 9px;
animation:fadeInLeft 1s ease forwards;
animation-delay: 2.6s;
transform: translateX(800px);
}
.landing_line2{
top: 43px;
left: 139px;
animation:fadeInLeft 1.3s ease forwards;
animation-delay: 2.8s;
transform: translateX(800px);


}
.landing_line3{

top: 90px;
left: 29px;
animation:fadeInLeft 1s ease forwards;
animation-delay: 2.7s;
transform: translateX(800px);

}
.landing_line4{
   
top: 133px;
left: 195px;
animation:fadeInLeft 1.2s ease forwards;
animation-delay: 2.75s;
transform: translateX(800px);

}
.landing_line5{

top: 193px;
left: 105px;
animation:fadeInLeft 1.3s ease forwards;
animation-delay: 2.8s;
transform: translateX(800px);
}

@keyframes fadeInLeft{
    0%{transform:translateX(800px); opacity: 0;}
    100%{transform:translateX(0); opacity: 1;}
  }



.landing_particle-top{
    position:absolute;
    top:0;
    left: 0;
    width: 444px;
    height: 447px;
    z-index: 2;
    object-fit: contain;

}

.landing_particle-bottom{
    position:absolute;
    bottom:0;
    right: 0;
    width: 486px;
    height: 479px;
    z-index: 2;
    object-fit: contain;
    
}

.landing_particle-top img{
    width: 70%;
    height: 70%;
}
.landing_particle-bottom img{
    width: 70%;
    height: 70%;
    position:absolute;
    bottom:0;
    right: 0;
}



@media screen and (max-width:990px){
    .landing_home{
        height: auto;
    }
    .landing_main{
        display: flex;
        flex-direction: column-reverse;
    }
     .landing_main section{
           width: 100%;
           height: auto;
         
    }
    .landing_left-section{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .landing_left-section div{
        max-width: 90%;
        max-height: 90%;
     
    }
    .landing_particle-bottom{
      width: 300px;
      height: 300px;
    }
    .landing_particle-top{
        width: 300px;
        height: 300px;
    }
    .landing_right-section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .landing_line1,.landing_line2,.landing_line3,.landing_line4,.landing_line5{
        position: absolute;
        width: 211.13px;
    height: 9.52px;
    }
    .landing_top-lines{
        height: 100px;
    }
    .landing_bottom-lines{
        height: 200px;
    }

    .landing_content{
        width: 100%;
    height:187px;
    position: relative;
    gap: 24px;
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
    }
    .landing_content h1{
    font-size: 56px;
    line-height: 115.2px;
    width: 100%;
    height: 85px;
    text-align: center;
    color: #FFFFFF !important;
    }
    .landing_content span{
        height: 48px;
        width: 100%;
        text-align: center;
        max-width: 400px;
        color: #FFFFFF !important;
    
    }

    .landing_line1{
        top: 3px;
    left: 20%;

    }
    .landing_line2{
    top: 43px;
    left: 36%;
    
    }
    .landing_line3{
    
    top: 90px;
    left: 15%;
  
    
    }
    .landing_line4{
       
    top: 133px;
    left: 40%;
 
    
    }
    .landing_line5{
    
    top: 193px;
    left: 28%;

    }

}