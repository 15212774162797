.custom-number-input {
    display: flex;
    align-items: center;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    overflow: hidden;
    height: 2.125rem;
  
}

.custom-number-input input {
    border: none;
    outline: none;
   
    font-size: 14px;
    font-weight: 400;
    flex-grow: 1;
    width: 100%;
    font-family: Figtree;
}
.custom-number-input input:focus{
    border:none;
}

.custom-number-input .buttons {
    display: flex;
    flex-direction: column;
 
    border-left: 1px solid #dcdcdc;
   
    color: #4D4D4D;
   
width: 37px;
height: 37px;
border-radius: 0px var(--Radius-XS, 4px) var(--Radius-XS, 4px) 0px;

background: var(--Backgrounds-white, #FFF);

}

.custom-number-input .buttons button {
    border: none;
    background: transparent;
    cursor: pointer;

    height: 50%;
    width: 100%;
}

.custom-number-input .buttons button img {
    display: flex;
    padding: 5px 3px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
}
.custom-number-input .buttons .increment{
    border-bottom: 1px solid var(--boarders-lines-neutral-subtle, #D0D0D0);
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}