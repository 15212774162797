

.ql-editor {
  min-height: 400px; /* Ensure a minimum height for the editor */
  font-family: Arial, sans-serif;
}

.child-container{
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    /* position: absolute; */
    position: relative;
    text-align:left;
    /* top: 80%; */
    /* left: 50%;
    transform: translate(-50%, -50%);  */
  
    width: 90%;
    max-width: 800px;
    padding: 20px;
    background-color: white;
    gap: 10px;
}

.quill-editor{
  width: 100%;
}
.quill-editor .ql-container.ql-snow{
border: none !important;
}

.ql-toolbar.ql-snow {
  border-left: none !important;
  border-right: none !important;
}


.card-title {
  height: 25px;
  width: 60%;
  /* margin: 0; */

  margin: 0 0 20px 0;
}

.card-excerpt {
  color: #777;
  margin: 10px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  line-height: 0.8rem;
}

.animated-bg {
  background-image: linear-gradient(to right,
          #f6f7f8 0%,
          #edeef1 10%,
          #f6f7f8 20%,
          #f6f7f8 100%);
  background-size: 200% 100%;
  animation: bgPos 1s linear infinite;
}

.animated-bg-text {
  border-radius: 50px;
  display: inline-block;
  margin: 0;

}

@keyframes bgPos {
  0% {
      background-position: 50% 0;
  }

  100% {
      background-position: -150% 0;
  }
}