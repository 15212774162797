.analytics-container{
    padding: 24px;
    background-color: #F5F5F5;
    height: 100%;
    gap: 42px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}
.analytics-header{
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.analytics-header>div:nth-child(1){
    color: #1F1F1F;
    font-family: Figtree;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.analytics-header>div:nth-child(2){
    color: #4D4D4D;

/* Body/Body 03/14 - Regular */
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.custom-title{
    color: #1F1F1F;
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.analytics-container>div:nth-child(2){
    display: flex;
    flex-direction: column;
    gap: 26px;
}
.analytics-subcontainer1{
    display: flex;
    flex-direction: row;
    gap: 24px;
    
}
.analytics-subcontainer2{
    display: flex;
    flex-direction: row;
    gap: 24px;
    
}
.analytics-subcontainer1>div:nth-child(1){
    display: flex;
min-width: 696px;
width: 60%;
padding: var(--M-M, 24px);
flex-direction: column;
align-items: flex-start;
border-radius: var(--Radius-3XS, 8px);
background: var(--Backgrounds-white, #FFF);

}
.analytics-subcontainer1>div:nth-child(2){
    display: flex;
min-width: 396px;
width: 40%;
height: 392px;
padding: var(--M-M, 24px);
flex-direction: column;
align-items: flex-start;
gap: var(--M-M, 24px);
flex-shrink: 0;
border-radius: var(--XS-3XS, 8px);
background: var(--Backgrounds-white, #FFF);
}
.analytics-subcontainer2>div:nth-child(2){
    display: flex;
min-width: 696px;
width: 60%;
padding: var(--M-M, 24px);
flex-direction: column;
align-items: flex-start;
border-radius: var(--Radius-3XS, 8px);
background: var(--Backgrounds-white, #FFF);
gap:24px;

}
.analytics-subcontainer2>div:nth-child(1){
    display: flex;
min-width: 396px;
width: 40%;
height: 392px;
padding: var(--M-M, 24px);
flex-direction: column;
align-items: flex-start;
gap: var(--M-M, 24px);
flex-shrink: 0;
border-radius: var(--XS-3XS, 8px);
background: var(--Backgrounds-white, #FFF);
}

.Select-an{
    /* display: flex; */
    width: 156px;
/* padding: var(--XS-4XS, 10px) var(--S-3S, 16px);
align-items: flex-start;
gap: var(--XS-3XS, 8px); */
}
