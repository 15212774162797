.applicationcard-container{
    background-color: #F5F5F5;
    padding: 24px;
    margin-top: 24px;
}
.applicationcard-container>div:nth-child(1){
    color: var(--Texts-title_neutral, #1F1F1F);

/* Body/Body 03/Body 02/16 - Semi Bold */
font-family: Figtree;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.applicationcard-container>div:nth-child(2){
    color: var(--Texts-body, #4D4D4D);

/* Body/Body 03/14 - Regular */
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.applicationcard-footer{
    color: var(--Texts-title_neutral, #1F1F1F);

/* Body/Body 03/Body 02/16 - Semi Bold */
font-family: Figtree;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.applicationcard-footer1{
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}