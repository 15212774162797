.vacancyanalysis-container {
    min-width: 576px;
  
    /* height: 352px; */
    padding: 32px; /* Example padding */
    border-radius: 8px;
  background: var(--Neutral-0, #FFF);
  /* margin-top: 24px;
  margin-right: 24px; */
    }
  .text-color-class{
      color: black;
    }
  .labels{
      display: flex;
      flex-direction: row;
    }
  .legend-text {
      font-size: 14px;
      
    }
  .legend-color{
      width: 12px;
      height: 12px;
    }
  .vacancyanalysis-container h2{
    color: var(--Texts-title_neutral, #1F1F1F);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .vacancyanalysis-container p{
    color: var(--Texts-body, #4D4D4D);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

  }
  .vacancyanalysis-subcontainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .vacancyanalysis-subcontainer>div:nth-child(1)>div:nth-child(1){
    color: var(--Texts-title_neutral, #1F1F1F);
  font-family: Figtree;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
  .vacancyanalysis-subcontainer>div:nth-child(1)>div:nth-child(2){
    color: var(--Texts-body, #4D4D4D);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .vacancyanalysis-search{
    display: flex;
width: 216px;
padding-left: 36px;
align-items: flex-start;
border-radius: 0px;
  }
  .ant-select-selector {
    border-radius: 4px !important; /* Adjust the value as needed */
  }