.newcard-container>div:nth-child(1){
    color: var(--Texts-title_neutral, #1F1F1F);

/* Title/32 - Bold */
font-family: Figtree;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.newcard-container>div:nth-child(2){
    color: var(--Texts-body, #4D4D4D);

/* Body/Body 03/14 - Regular */
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.newcard-container>div:nth-child(3){
    color: var(--Texts-link, #1890FF);

/* Sub-title/12 - Regular */
font-family: Figtree;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.newcard-container1>div:nth-child(1){
    color: var(--Texts-title_neutral, #1F1F1F);

/* Body/Body 03/Body 02/16 - Semi Bold */
font-family: Figtree;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.newcard-container>div:nth-child(2){
    color: var(--Texts-body, #4D4D4D);

/* Body/Body 03/14 - Regular */
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}