.no-data-header{
    color: var(--Texts-title_neutral, #1F1F1F);
text-align: center;

/* Body/Body 01/18 - Semi Bold */
font-family: Figtree;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.no-data-sub{
    color: var(--Texts-body, #4D4D4D);
text-align: center;

/* Body/Body 03/14 - Regular */
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom:  24px;
}
.button-addnotes{
    background-color: #1890FF;
    width: 160px;
    height: 40px;
    border-radius: 4px;
}
.addnotes-header{
    display: flex;
    flex-direction: column;
}

.addnotes-header>div:nth-child(1){
    color: var(--Texts-title_neutral, #1F1F1F);

/* Title/20 - Bold */
font-family: Figtree;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.addnotes-header>div:nth-child(2){
    color: var(--Texts-body, #4D4D4D);

/* Body/Body 03/14 - Regular */
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.addnotes-content .ant-modal-body{
    height: 50vh !important;
    overflow-y: scroll;
}
.addnotes-content .ant-modal-content{
    height: 70vh;
    width: 90vh;
}

.quill-editor{
width: 100%;
overflow-y: scroll;
}
.quill-editor .ql-container.ql-snow{
border: none !important;
}
