/* Override the default carousel dot styles */
.ant-carousel .slick-dots li button {
    width: 8px;
    height: 8px;
    background-color: #1890FF; /* Change dot color */
    border-radius: 50%;
    opacity: 0.16;

  }
  .ant-carousel .slick-dots li button{
    margin-bottom: 40px;
 
  }
  
  .ant-carousel .slick-dots li.slick-active button {
    background-color: #1890FF; /* Change active dot color */
  }
  
  /* Uniform spacing for dots, regardless of position */
  .ant-carousel .slick-dots {
    display: flex !important;
    justify-content: center; /* Center the dots */
    gap: 10px; /* Uniform space between dots */
  }
  
  .ant-carousel .slick-dots li {
    margin: 0; /* Remove default margin to control spacing with 'gap' */
  }
  .slidecard-container{
    display: flex;
    min-width: 396px;
    width: 40%;
    padding: var(--M-M, 24px);
    flex-direction: column;
    /* align-items: center; */
    gap: var(--M-M, 24px);
    border-radius: var(--XS-3XS, 8px);
    /* border-top-left-radius: var(--XS-3XS, 8px);
border-top-right-radius: var(--XS-3XS, 8px); */
    background: var(--Backgrounds-white, #FFF);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);

    
    margin-top: 4rem;
  }
