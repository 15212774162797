/* General table header cell styling */

.table-wrapper{
  max-height: 670px;
  width: 100%; 
  overflow-y: scroll;
}

.table-fixed-column-outter {
  position: relative;
  width: 100%; 
  height: max-content;
  display: flex;
  flex-direction: column;
}

.table-fixed-column-inner {

 overflow-y:hidden;
 overflow-x: scroll;
 min-height: 330px !important;
  padding-left: 200px;
 /* min-height: 70vh; */
 /* min-height: 90vh; */
/* margin-left: 160px; */



}


.custom-table.table.table-fixed-column {
  table-layout: fixed;
  width: 100%;
 
  
}

.custom-table.table td,
.custom-table.table th {
  /* width: 160px; */

}


.custom-table.table th:first-child{
  position: absolute;
  left: 0;
  min-width: 200px;
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;



}



.custom-table.table tr td:first-child {
  position: absolute;
  left: 0;
  min-width: 200px;
  z-index: 2;
  


}


 .custom-table.table tr td:first-child *{
  white-space: nowrap;   /* Prevent text wrapping */
  overflow: hidden;      /* Hide text overflow */
  text-overflow: ellipsis;
  max-width: 200px;
  /* display: block; */
  z-index: 1;
}
.custom-table.table th:first-child{
  min-height: 61px !important;
margin-top:0.5px;
}



.custom-table.table{
border-collapse: collapse;
}
.custom-table thead {
  background-color: #f0f0f0;
}

.custom-table th,.custom-table td {
  padding: 12px 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;

}

.custom-table th {
  
  /* overflow: hidden; */
  color: var(--Texts-body, #4D4D4D);
  text-overflow: ellipsis;
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-items: center;
  

}

.custom-table td {
  font-weight: normal;
  background-color: #ffffff;
  color: #161616;
}


.custom-table tr .table-headerCell{
  background-color: #E9E9E9;
  white-space: nowrap;   /* Prevent text wrapping */
  /* overflow-x: hidden;      */
  text-overflow: ellipsis;
  /* border-right: 2px solid #ddd;  */
  /* border: none; */


}

.custom-table tr .table-headerCell::after{
  content: ""; /* Empty content to create the line */
  position: absolute;
  top: 50%; /* Center the border vertically */
  right: 0; /* Align to the right edge */
  transform: translateY(-50%); /* Adjust position to center vertically */
  height: 50%; /* Set the height for a shorter border */
  width: 2px; /* Adjust thickness as needed */
  background-color: #ddd;

}
.custom-table  .table-cell{
  max-width: 160px !important;
min-width: fit-content;
min-height: 61px;
/* padding: var(--S-3S, 16px); */
align-items: center;
gap: 15px;
align-self: stretch;
/* max-width: 100px; */
/* white-space: nowrap;   
overflow: hidden;      
text-overflow: ellipsis; */
z-index: 1;
color: #161616;
place-content: center;
position: relative;
overflow: visible;

 }
 
 .custom-table  .table-cell *{
  white-space: nowrap;   /* Prevent text wrapping */
  overflow: hidden;      /* Hide text overflow */
  text-overflow: ellipsis;

 }




.tooltip-container {
  position: fixed;
  display: none;
  background-color: white;
  color: black;
  border: 0.5px solid black;
  padding: 5px;
  max-width: 50vw;
  max-height: max-content;
  width: max-content;
  font-size: 12px;
  border-radius: 1px;
  white-space: wrap;
  z-index: 999999999999; /* Ensure it is above all other elements */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

  


 .custom-table .table-headerCell{
  position: relative;
}

/* Dropdown container */
.custom-table .table-dropdown {
  position: absolute;
  top: 30px; /* Adjust based on header height */
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 100%; /* Set width as per design */
  z-index: 2;
  border-radius: 5px;
  min-width: 160px;
max-height: 280px;
overflow-y: scroll;

}
.column-filter .table-dropdown {
  position: absolute;
  top: 40px; /* Adjust based on header height */
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 100%; /* Set width as per design */
  z-index: 4;
  border-radius: 5px;
  min-width: 300px;
max-height: 450px;
overflow-y: scroll;
font-size: 14px;
color: #333;
font-weight: 600;
}

/* Multi-select dropdown */
.custom-table .multi-select-dropdown , .search-input{
  display: flex;
  flex-direction: column;
  position: relative;
}

.column-filter .multi-select-dropdown , .search-input{
  display: flex;
  flex-direction: column;
  position: relative;
}

.custom-table .search-input input[type="text"] {
  /* padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px; */


}
.custom-table input[type="checkbox"]{
  cursor: pointer;
} 

.column-filter input[type="checkbox"]{
  cursor: pointer;
}

.custom-table .multi-select-dropdown .options-list {
  max-height: 115px; 
   overflow-y: auto;
 position: relative;
  /* border: 1px solid #ddd; */
  padding: 5px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
}
.column-filter .multi-select-dropdown .options-list{
  max-height: 280px; 
  min-height: 100px;
   overflow-y: auto;
 position: relative;
  /* border: 1px solid #ddd; */
  padding: 5px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.column-filter-icon-active>path {
  fill:#1890FF ;
}
.column-filter-icon-inactive>path {
  fill: #161616;
}

/* Styling individual option items */
.custom-table .multi-select-dropdown .options-list div {
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.column-filter .multi-select-dropdown .options-list div{
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.custom-table .multi-select-dropdown .options-list label {
  margin-left: 8px;
  font-size: 14px;
  color: #333;
}
.column-filter .multi-select-dropdown .options-list label {
  margin-left: 8px;
  font-size: 14px;
  color: #333;
}

/* Single-select dropdown */
.custom-table .single-select-dropdown ,.column-filter .single-select-dropdown{
  display: flex;
  flex-direction: column;
}





.custom-table .single-select-dropdown .options-list {
  display: flex;
  flex-direction: column;
  max-height: 150px;
  width: 100%;
  overflow-y: auto;
}

.column-filter .single-select-dropdown .options-list{
  display: flex;
  flex-direction: column;
  max-height: 150px;
  width: 100%;
  overflow-y: auto;
}

.custom-table .option-item ,.column-filter .option-item{
  padding: 8px;
  
  cursor: pointer;
  /* border-bottom: 1px solid #ddd; */
}


.custom-table .option-item.selected , .column-filter  .option-item.selected{
  background-color: #f0f0f0;
  font-weight: bold;
}

.custom-table  .option-item:hover, .column-filter  .option-item:hover{
  background-color: #e0e0e0;
}




/* Buttons */

.custom-table  .actions,.column-filter  .actions{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.custom-table .actions button, .column-filter .actions button {
  padding: 8px 10px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 5px;
  width: 50%;
}

.custom-table .actions button:hover {
  /* background-color: #ddd; */
}

.custom-table  .actions button:last-of-type,.column-filter .actions button:last-of-type  {
  background-color: #007bff;
  color: white;
  margin-right: 5px;
}

.custom-table .actions button:last-of-type:hover,.column-filter .actions button:last-of-type:hover {
  background-color: #0056b3;
}

.custom-table .actions button:first-of-type ,.column-filter .actions button:first-of-type{
  background-color: transparent;
  color: #c8cacb;
  font-weight: 600;
}


/* Select All checkbox */
.custom-table .multi-select-dropdown > div,.column-filter .multi-select-dropdown > div{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.custom-table  .multi-select-dropdown label,.column-filter .multi-select-dropdown label {
  margin-left: 8px;
  font-size: 14px;
}

/* Close dropdown on click outside */
.custom-table .dropdown-close-overlay,.column-filter .dropdown-close-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: transparent;
}




.no-table-data{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 400px;
  background: white;

  border-radius: 4px;
  margin-bottom: 20px;
}
.no-filter{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 80vh;
  background: white;
 
  border-radius: 4px;
  margin-bottom: 20px;
}

.no-table-data img{
  width: 160px;
  height: 160px;

}
 .no-table-data h3{
  margin: 2px 0px;
  font-size: 18px;
  color: #1F1F1F;
  font-weight: 700;
}

 .no-table-data p{
  margin: 2px 0px;
  font-size: 14px;
  color: #4D4D4D;
  font-weight: 500;
}
.table-filter-box{
  width: fit-content;
  height: fit-content;
  padding: 2px;
  border-radius: 3px;
}
.table-filter-box:hover,.table-filter-box-active{


  background-color: #c9c7c7;
  cursor: pointer;
 
}

.table-filter-title{
  display: flex;
  justify-content: center;
  align-items: center;

}
.active-job-filter-active,.active-job-filter-inactive{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #ddd;
  min-width: 200px;
  height: 32px;
  background: #ffffff;
  padding: 10px;
  cursor: pointer;
}
.active-job-filter-active:hover,.active-job-filter-inactive:hover{
  border: 1px solid #1890FF !important;
}
.active-job-filter-active span{
  color: #d0d0d0;
  font-size: 12px;
  font-weight: 400;
}
.option-label{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.option-label span{
width: max-content;

}

.option-dot{
  width: 4px !important;
  height: 4px !important;
  border-radius:100px;
  background-color: #7A7A7A;

}
.selected-job-tags{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;


}
.selected-job-tag{
  width: max-content;
  max-width: 120px;
  height: 25px;
  padding: 5px;
  border-radius: 3px;
  background-color: rgba(221, 221, 221,0.6);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
 

}
.tag-job-name{
  max-width: 100px;
  color: #1F1F1F !important;
  white-space: nowrap;   /* Prevent text wrapping */
  overflow: hidden;      /* Hide text overflow */
  text-overflow: ellipsis;
 cursor: default;

}
.tag-remove-icon{
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}
.tag-remove-icon:hover{

  background-color: rgba(221, 221, 221,1);
}

.selected-job-tag span path{
  fill:  #1F1F1F !important;

}